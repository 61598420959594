@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --secondary: #f0e1e2b4;
  --secondaryL: #cec0c1b4;
  --accentT: #feddb4e4;
  --accent: #feddb4b4;
  --accentL: #d6ba98;
  --primary: #fefbfa;
  --primaryL: #e4d0c9;
  --textColor: #291215;
  --translucent: #00000033;
  --semi-transparent: #000000c5;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--textColor);
  -webkit-tap-highlight-color: transparent;
  white-space: pre-wrap;
}
body{
  color: var(--textColor);
  background-color: var(--primary);
  
}
.textPath{
  fill: var(--textColor)
}
.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.centeredButton{
  margin-left: 5px;
  
  background-color: transparent;
}
.menuButton:hover{
  background-color: var(--translucent);
}
#Alert{
  visibility: hidden;
}
#Checkmark{
  visibility: hidden;
}
#locationCircle{
  fill: var(--accentT);
}
#addressGrid{
  grid-template-rows: 320px calc(100% - 320px);
}
@media (min-width: 640px) {
  #navGrid{
    left: 0px;
  }
  .imgRight{
    grid-template-columns: 65% 35%;
  }
  .imgLeft{
    grid-template-columns: 35% 65%;
  }
}
@keyframes outterDotAni{
    0% {
      width: 10px;
      height: 10px;
    }
    5%{
      width: 25px;
      height: 25px;
    }
    80%{
      width: 25px;
      height: 25px;
    }
    100% {
      width: 10px;
      height: 10px;
    } 
}
@keyframes innerDotAni{
  0% {
    background-color: var(--translucent);
    width: 20%;
    opacity: 0;
  }
  5% {
    width: 20%;
    background-color: var(--accent);
    opacity: 1;
  }
  95%{
    width: 100%;
    background-color: var(--accent);
    opacity: 1;
  }
  100% {
    background-color: var(--translucent);
    width: 100%;
    opacity: 0;
  }
}
@keyframes cardFade{
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95%{
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideSide{
  from {
    transform: translateY(100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes growUp{
  from {
    transform-origin: center center;
    transform: scale(1) translateY(0);
    -webkit-filter: blur(0px);
  }
  to {
    transform-origin: center center;
    transform: scale(1.5) translateY(100px);
    -webkit-filter: blur(5px);
  }
}
@keyframes fallDown{
  0%{
    
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes throwSpin{
  0% {
    transform: translateX(0);
    transform: rotate(0deg);
  }
  100% {
    transform: translateX(100%);
    transform: rotate(270deg);
  }
}
@keyframes fadeOut{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: var(--primary);
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  margin: 2px;
  border-radius: 10px;
}

::-webkit-scrollbar-button:end:increment {
  height: 10px;
  display: block;
  background: var(--primary);
}

::-webkit-scrollbar-button:start:increment {
  height: 10px;
  display: block;
  background: var(--primary);
}